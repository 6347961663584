import HTTP from '@/api/http'

const getOrders = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/orders`, {
        params: params,
    }).then(responseHandler).catch(errorHandler)
}
const getOrderId = (id, responseHandler, errorHandler) => {
    return HTTP.get(`/orders/${id}`).then(responseHandler).catch(errorHandler)
}
const patchOrderId = (data, responseHandler, errorHandler) => {
    return HTTP.patch(`/orders/${data.id}`, {
        ...data
    }).then(responseHandler).catch(errorHandler)
}
const sendOrderLike = (id, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${id}/like`).then(responseHandler).catch(errorHandler)
}
const sendOrderDisLike = (id, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${id}/dislike`).then(responseHandler).catch(errorHandler)
}
const sendRejectionReasons = (id, responseHandler, errorHandler) => {
    return HTTP.get(`/rejection-reasons`).then(responseHandler).catch(errorHandler)
}
const sendOrderComments = (data, responseHandler, errorHandler) => {
    return HTTP.get(`/orders/${data.id}/comments`, {
        params: {
            ...data
        }
    }).then(responseHandler).catch(errorHandler)
}
const postOrderComment = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/comments`, {
        ...data
    }).then(responseHandler).catch(errorHandler)
}
const patchOrderComment = (data, responseHandler, errorHandler) => {
    return HTTP.patch(`/orders/${data.id}/comments/${data.commentId}`).then(responseHandler).catch(errorHandler)
}
const deleteOrderComment = (data, responseHandler, errorHandler) => {
    return HTTP.delete(`/orders/${data.id}/comments/${data.commentId}`).then(responseHandler).catch(errorHandler)
}
const sendOrderParticipants = (data, responseHandler, errorHandler) => {
    return HTTP.get(`/orders/${data.id}/participants`, {
        params: {
            take: data.take || 20,
            skip: data.skip || 0,
            status: data.status || null
        }
    }).then(responseHandler).catch(errorHandler)
}
const sendOrderProposedParticipants = (data, responseHandler, errorHandler) => {
    return HTTP.get(`/orders/${data.id}/proposed-participants`, {
        params: data
    }).then(responseHandler).catch(errorHandler)
}
const createParticipationRequests = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/participation-request`).then(responseHandler).catch(errorHandler)
}

// moderator
const sendOrderAccept = (id, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${id}/accept`).then(responseHandler).catch(errorHandler)
}
const sendOrderReject = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/reject`, {
        ...data
    }).then(responseHandler).catch(errorHandler)
}
const sendOrderClose = (id, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${id}/close`).then(responseHandler).catch(errorHandler)
}
const sendParticipantsApprove = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/participants-approve`,  {
        userIds: data.ids
    }).then(responseHandler).catch(errorHandler)
}
const createParticipants = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/participants`,  {
        userIds: data.ids
    }).then(responseHandler).catch(errorHandler)
}
const sendParticipantsDeny = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/participants-deny`, {
        userIds: data.ids,
        denyComment: data.denyComment,
        denyFiles: data.denyFiles
    }).then(responseHandler).catch(errorHandler)
}
const sendParticipationRequests = (data, responseHandler, errorHandler) => {
    return HTTP.get(`/orders/${data.id}/participation-requests`, {
        params: data
    }).then(responseHandler).catch(errorHandler)
}
const deleteOrderParticipants = (data, responseHandler, errorHandler) => {
    return HTTP.delete(`/orders/${data.id}/participant/${data.userId}`).then(responseHandler).catch(errorHandler)
}
const editDamage = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${payload.id}/damage`, {
        damage: payload.damage
    }).then(responseHandler).catch(errorHandler)
}
const orderSubscribe = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/subscribe`, ).then(responseHandler).catch(errorHandler)
}
const orderUnsubscribe = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/unsubscribe`, ).then(responseHandler).catch(errorHandler)
}

const orderConfirm = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/participation-confirm`, ).then(responseHandler).catch(errorHandler)
}

const orderRefuse = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/participation-refuse`, {
        refuseComment: data.comment
    }).then(responseHandler).catch(errorHandler)
}

const orderRequest = (data, responseHandler, errorHandler) => {
    return HTTP.post(`/orders/${data.id}/participation-request`, {
        requestComment: data.requestComment,
        requestFiles: data.requestFiles,
    }).then(responseHandler).catch(errorHandler)
}

const orderDamage = (data, responseHandler, errorHandler) => {
    return HTTP.get(`/damage/${data.id}`).then(responseHandler).catch(errorHandler)
}

const orderDownload = (data, responseHandler, errorHandler) => {
    return HTTP.get(`/orders/${data.id}/docx`).then(responseHandler).catch(errorHandler)
}

const orderDamageRanges = (data, responseHandler, errorHandler) => {
    return HTTP.get(`/damage-ranges`).then(responseHandler).catch(errorHandler)
}

const orderDownloadPdf = (data, responseHandler, errorHandler) => {
    return HTTP.get(`/orders/${data.id}/pdf?scale=${data.scale}`).then(responseHandler).catch(errorHandler)
}
export default {
    getOrders,
    getOrderId,
    patchOrderId,
    sendOrderLike,
    sendOrderDisLike,
    sendRejectionReasons,
    sendOrderComments,
    postOrderComment,
    patchOrderComment,
    deleteOrderComment,
    sendOrderParticipants,
    sendOrderProposedParticipants,
    createParticipationRequests,
    sendOrderAccept,
    sendOrderReject,
    sendOrderClose,
    sendParticipantsApprove,
    createParticipants,
    sendParticipantsDeny,
    sendParticipationRequests,
    deleteOrderParticipants,
    editDamage,
    orderSubscribe,
    orderUnsubscribe,
    orderConfirm,
    orderRefuse,
    orderRequest,
    orderDamage,
    orderDownload,
    orderDamageRanges,
    orderDownloadPdf
}
