import HTTP from '@/api/http'

const getDocuments = (responseHandler, errorHandler) => {
    return HTTP.get(`/support/manuals`).then(responseHandler).catch(errorHandler)
}

const addDocument = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/support/manuals`, {
        name: payload.name,
        file: payload.file,
        type: payload.type,
        url: payload.url
    }).then(responseHandler).catch(errorHandler)
}

const editDocument = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/support/manuals/${payload.id}`, {
        name: payload.name,
        file: payload.file,
        type: payload.type,
        ord: +payload.ord,
        url: payload.url
    }).then(responseHandler).catch(errorHandler)
}

const deleteDocument = (id, responseHandler, errorHandler) => {
    return HTTP.delete(`/support/manuals/${id}`).then(responseHandler).catch(errorHandler)
}

export default {
    getDocuments,
    addDocument,
    editDocument,
    deleteDocument
}
